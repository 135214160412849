import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons/faCheckSquare';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons/faShieldAlt';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { faBatteryFull } from '@fortawesome/free-solid-svg-icons/faBatteryFull';
import { faIndustry } from '@fortawesome/free-solid-svg-icons/faIndustry';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons/faSnowflake';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faSignal } from '@fortawesome/free-solid-svg-icons/faSignal';
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons/faMinusSquare';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faChartArea } from '@fortawesome/free-solid-svg-icons/faChartArea';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faHourglass } from '@fortawesome/free-solid-svg-icons/faHourglass';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons/faFileAlt';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons/faHandPointRight';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl';
import { faCut } from '@fortawesome/free-solid-svg-icons/faCut';
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faHourglassStart } from '@fortawesome/free-solid-svg-icons/faHourglassStart';
import { faClone } from '@fortawesome/free-regular-svg-icons/faClone';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons/faCodeBranch';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons/faCommentDots';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import { faPercent } from '@fortawesome/free-solid-svg-icons/faPercent';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons/faPuzzlePiece';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faThermometerFull } from '@fortawesome/free-solid-svg-icons/faThermometerFull';
import { faThermometerEmpty } from '@fortawesome/free-solid-svg-icons/faThermometerEmpty';
import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons/faTemperatureHigh';
import { faTemperatureLow } from '@fortawesome/free-solid-svg-icons/faTemperatureLow';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faMapSigns } from '@fortawesome/free-solid-svg-icons/faMapSigns';
import {faPlug} from '@fortawesome/free-solid-svg-icons/faPlug';
import {faLightbulb} from '@fortawesome/free-solid-svg-icons/faLightbulb';

import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faRssSquare } from '@fortawesome/free-solid-svg-icons/faRssSquare';

import { library, dom } from '@fortawesome/fontawesome-svg-core';

library.add(
  faMinus,
  faChevronRight,
  faCaretRight,
  faCheckSquare,
  faDollarSign,
  faShieldAlt,
  faSun,
  faBatteryFull,
  faIndustry,
  faSnowflake,
  faMapMarkerAlt,
  faSignal,
  faFire,
  faQuestionCircle,
  faGlobeAmericas,
  faPlusSquare,
  faSyncAlt,
  faMinusSquare,
  faUniversity,
  faUpload,
  faChartArea,
  faCalendarAlt,
  faCalendar,
  faHourglass,
  faArrowAltCircleLeft,
  faDownload,
  faTable,
  faFileAlt,
  faExclamationTriangle,
  faHandPointRight,
  faHome,
  faAngleDown,
  faListUl,
  faCut,
  faCalculator,
  faCheck,
  faTimes,
  faHourglassStart,
  faClone,
  faChartBar,
  faCog,
  faBolt,
  faWindowClose,
  faPlusCircle,
  faMinusCircle,
  faBuilding,
  faCogs,
  faCodeBranch,
  faCommentDots,
  faEnvelope,
  faWrench,
  faArrowCircleRight,
  faPercent,
  faPuzzlePiece,
  faUser,
  faThermometerFull,
  faThermometerEmpty,
  faTemperatureHigh,
  faTemperatureLow,
  faGlobe,
  faMapSigns,
  faPlug,
  faLightbulb,

  faGoogle,
  faFacebookSquare,
  faFacebookF,
  faTwitter,
  faLinkedin,
  faYoutube,
  faInstagram,
  faRssSquare,
);

dom.watch();
